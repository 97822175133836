/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import {enquireScreen} from 'enquire-js';


import Banner0 from './Banner0';
import Feature1 from './Feature1';
import Feature4 from './Feature4';
import Content4 from './Content4';
import Advantage from './Advantage';
import Team from './Team';
import Nav3 from './Nav3';
import Footer1 from './Footer1';

import {
    Nav30DataSource,
    Banner00DataSource,
    Feature10DataSource,
    Feature40DataSource,
    Content40DataSource,
    Feature00DataSource,
    Pricing00DataSource,
    Footer10DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const {location} = window;

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({isMobile: !!b});
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        document.getElementsByTagName('body')[0].scrollTop = 0;
        /* 如果不是 dva 2.0 请删除 end */
    }

    render() {
        const children = [
            <Nav3
                id="Nav3_0"
                key="Nav3_0"
                dataSource={Nav30DataSource}
                isMobile={this.state.isMobile}
            />,
            <Banner0
                id="Banner0_0"
                key="Banner0_0"
                dataSource={Banner00DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature1
                id="Feature1_0"
                key="Feature1_0"
                dataSource={Feature10DataSource}
                isMobile={this.state.isMobile}
            />,
            <Content4
                id="Content4_0"
                key="Content4_0"
                dataSource={Content40DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature4
                id="Feature4_0"
                key="Feature4_0"
                dataSource={Feature40DataSource}
                isMobile={this.state.isMobile}
            />,
            <Team
                id="Pricing0_0"
                key="Pricing0_0"
                dataSource={Pricing00DataSource}
                isMobile={this.state.isMobile}
            />,
            <Advantage
                id="Feature0_0"
                key="Feature0_0"
                dataSource={Feature00DataSource}
                isMobile={this.state.isMobile}
            />
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}
