import React, { Component } from 'react';
import { HashRouter as Router, Link, Route } from 'react-router-dom';
import './App.css';
import Home from './Home';
import Serve from './Serve';
import News from './News';
import Company from './Company';
import Nav3 from './Home/Nav3';
import Footer1 from './Home/Footer1';
import NewsDetail from './News/NewsDetail';

import { enquireScreen } from 'enquire-js';
import {
  Footer10DataSource,
  Nav30DataSource
} from './Home/data.source';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"location
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;


// const Home = () => (
//   <div>
//     <Home></Home>
//   </div>
// )

let isMobile;
const { location } = window;
enquireScreen((b) => {
  isMobile = b;
});



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }
  render() {
    return (
      <Router>
        <div className="App">
          {/* <Nav3
            id="Nav3_0"
            key="Nav3_0"
            dataSource={Nav30DataSource}
            isMobile={this.state.isMobile}
          /> */}
          <Route path="/" exact component={Home}></Route>
          <Route path="/home" exact component={Home}></Route>
          <Route path="/news" component={News}></Route>
          <Route path="/newdetail" component={NewsDetail}></Route>
          <Route path="/serve" component={Serve}></Route>
          <Route path="/company" component={Company}></Route>
          <Footer1
            id="Footer1_0"
            key="Footer1_0"
            dataSource={Footer10DataSource}
            isMobile={this.state.isMobile}
          />
        </div>
      </Router>
    );
  }
}

export default App;
