import React from 'react';
import {TweenOneGroup} from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import {Row, Col, Divider} from 'antd';
import {
    LeftOutlined,
    RightOutlined
} from '@ant-design/icons';

const style = {background: '#0092ff', padding: '8px 0'};

class Content12 extends React.PureComponent {


    propertyScroll(type) {
        let dom = document.getElementById("content")
        let left = dom.scrollLeft
        if (type == 'left') {
            dom.scrollTo(left - 258, 0)
        } else if (type == 'right') {
            dom.scrollTo(left + 258, 0)
        }
    };

    render() {
        const data = [
            {
                img: "/img/%E5%85%AC%E5%8F%B8-%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%831.png",
                title: "专利",
                country: "美国",
                no: "62366252",
                date: "2016年7月25日",
                applicant: "Leridge Global Corporation",
                name: "ASSEMBLY MANUFACTURING SYSTEM USING ROBOTIC MOBILE EQUIPMENT AND ROBOTIC MOBILE MATERIAL CONTAINERS"
            },
            {
                img: "/img/%E5%85%AC%E5%8F%B8-%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%832.png",
                title: "专利",
                country: "美国",
                no: "15652860",
                date: "2016年7月18日",
                applicant: "Leridge Global Corporation",
                name: "Parallel Manufacturing Systems and Methods"
            },
            {
                img: "/img/%E5%85%AC%E5%8F%B8-%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%833.png",
                title: "专利",
                country: "中国",
                no: "201710611741.7",
                date: "2017年7月25日",
                applicant: "锐界全球公司",
                name: "并行制造系统和方法"
            },
            {
                img: "/img/%E5%85%AC%E5%8F%B8-%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%834.png",
                title: "专利",
                country: "印度",
                no: "201734026072",
                date: "2017年7月21日",
                applicant: "Leridge Global Corporation",
                name: "Parallel Manufacturing Systems and Method"
            }
        ]

        const colChildren = data.map((item) =>
            <div className="proerty-item">
                <img className="proerty-pic" src={item.img} alt="" />
                <div className="detail">
                    <div className="title">{item.title}</div>
                    <div className="separate"></div>
                    <div className="content-item">
                        <span className="label">国家：</span>
                        <span className="value">{item.country}</span>
                    </div>
                    <div className="content-item">
                        <span className="label">申请号：</span>
                        <span className="value">{item.no}</span>
                    </div>
                    <div className="content-item">
                        <span className="label">申请日：</span>
                        <span className="value">{item.date}</span>
                    </div>
                    <div className="content-item">
                        <span className="label">申请人：</span>
                        <span className="value">{item.applicant}</span>
                    </div>
                    <div className="content-item">
                        <span className="label">名称：</span>
                        <span className="value">{item.name}</span>
                    </div>
                </div>
            </div>
        )


        return (
            <div className="property-out">
                <div className="property">
                    <div class="module-title">
                        <span class="underline">知</span>识产权
                        <span className="title-tip">
          INTELLECTUAL PROPERTY RIGHTS
          </span>
                    </div>

                    <div className="carousel">
                        <div className="to-left" onClick={this.propertyScroll.bind(this, "left")}>
                            <LeftOutlined />
                        </div>
                        <div className="content" id="content">
                            <div className="content-box" style={{'width': data.length * 258 - 18 + 'px'}}>
                                {colChildren}
                            </div>
                        </div>
                        <div className="to-right" onClick={this.propertyScroll.bind(this, 'right')}>
                            <RightOutlined />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Content12;
