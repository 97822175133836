


import React from 'react';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from './utils';
import {NewsData} from '../News/data.source';
let num=0
const dataList=NewsData.list
let data=dataList[num]

class Content1 extends React.Component {

  
    
  getBlockChildren = (data) =>
    data.map(($item) => {
      const { ...item } = $item;
      const { title, img, content } = item;
      ['title', 'img', 'content'].forEach((key) => delete item[key]);
      return (
        <li key={item.name} {...item}>
          <span {...img}>
            <img src={img.children} width="100%" alt="img" />
          </span>
          <h2 {...title}>{title.children}</h2>
          <div {...content}>{content.children}</div>
        </li>
      );
    });


    changeNews=(type)=>{
      console.log(type)
      console.log(this)
      console.log(num)
      if(type=='next'){
        if(num<dataList.length-1){
          num++
        }else{
          num=0
        }
      }else if(type=='previous'){
        if(num>0){
          num--
        }else{
          num=dataList.length-1
        }
      }
      console.log(num)
      data=dataList[num]
      console.log(data)
      this.setState({});
    }
    totop(type){
      document.documentElement.scrollTop = document.body.scrollTop =0;
    };

  render() {
    // const { ...props } = this.props;
    // const { dataSource, isMobile } = props;
    // delete props.dataSource;
    // delete props.isMobile;
    // const ulChildren = this.getBlockChildren(dataSource.block.children);
    // const queue = isMobile ? 'bottom' : 'left';
    // const { ...tagProps } = props;
    // const { dataSource, isMobile } = tagProps;
    // delete tagProps.dataSource;
    // delete tagProps.isMobile;


      const contentChildren=data.content.map((item)=>
          <div className="content margin-bottom-24">{item}</div>
      )

    return (
      <div  className="news trademark">
      <div class="module-title padding-left-24 margin-top-54">
        <span class="underline">资</span>讯
        <span className="title-tip">
          NEWS
        </span>
        <div className="right-btn">
          <div class="previous" onClick={() => {this.changeNews('previous');}}>
            <div className="arrow-left"></div>
          </div>
          <div class="next" onClick={() => {this.changeNews('next');}}>
            <div className="arrow-right"></div>
          </div>
          <div className="go-detail">
            <a href="#/news" target="_self"  onClick={this.totop.bind(this)}>全部资讯</a>
            <span className="arrow-right"></span>
          </div>
        </div>
      </div>
      <Row className="row" gutter={24}>
        <Col className="gutter-row" span={2} xl={12} md={24} sm={24} xs={24}>
          <span className="img-span"></span>
          <div className="gutter-img" style={{background:'URL('+data.img+') no-repeat center center'}} alt=""></div>
        </Col>
        <Col className="gutter-row align-left" span={2} xl={12} md={24} sm={24} xs={24}>
          <div className="title">{data.title}</div>
          <div className="time">{data.time}</div>
          {/*<div className="content">{data.content}</div>*/}
            {contentChildren}

          <div className="go-detail">
            <a href={"#/news?id="+data.id} onClick={this.totop.bind(this)}>进入详情</a>
            <span className="arrow-right"></span>
          </div>
        </Col>
      </Row>
      </div>
    );
  }
}

export default Content1;

