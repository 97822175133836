import React from 'react';
import {TweenOneGroup} from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import {Row, Col, Divider} from 'antd';

const style = {background: '#0092ff', padding: '8px 0'};

class Introduction extends React.PureComponent {
    getChildrenToRender = (data) =>
        // console.log(data)
        data.map((item) => {
            return (
                <Col key={item.name} {...item}>
                    <div {...item.children.wrapper}>
            <span {...item.children.img}>
              <img src={item.children.img.children} alt="img" />
            </span>
                    </div>
                </Col>
            );
        });

    render() {


        return (
            <div className="introduction">
                <Row className="row" gutter={24}>
                    <Col className="gutter-row left" span={2} xl={12} md={12} sm={24} xs={24}>
                        <div class="module-title">
                            <span class="underline">关</span>于锐界
                            <span className="title-tip">
              ABOUT Leridge™
              </span>
                        </div>
                        {/*  <p>北京锐界科技有限公司成立月2014年3月，注册资金600万元，是一家自主研发智能AI和AMR机器人驱动系统应用于建设全球时装柔性智能供应链的高新科技类公司。
              </p>
            <p  className="margin-top-24">
              公司研发的柔性智能制造技术，拥有中国、美国和印度的发明专利，采用全球首创智能AI 和 AGV机器人赋能的智能矩阵柔性制造技术，以助力时装企业打造全平台的柔性供应链管理体系和实现时装全产业链数字化为目标。</p>*/}


                        <p>
                            北京锐界科技有限公司是基于自主学习型人工智能和机器人技术，专注于时尚行业的智能制造系统提供商。                        </p>
                        <p className="margin-top-24">
                            公司成立于2014年3月，总部位于北京。经过四年多的自主研发，公司于2018年推出了第一代基于自主学习型人工智能和机器人技术的智能制造系统，并在北京亦庄高新示范区建立的样板示范工厂，第二年便成为亦庄智能制造示范企业。2019年，专为海外服装品牌生产提供服务的南通服装智能工厂启动，Maggy London成为该工厂第一个国际品牌合作伙伴，2020年该工厂成为美国上市服装企业GIII全球供应商。 </p>
                        <p className="margin-top-24">
                            在实践中验证在实践中提高，公司与清华高性能计算所战略合作，共同深度开展AI生产调度算法研究，于2020年5月，研发完成了AI优化智能制造系统3.0版上线，即：Leridge™ -蓝锐 服装智能制造及管理系统。其核心的“并行制造系统和方法”分别获得了中国、美国和印度取得了专利权。 </p>
                        <p className="margin-top-24"> 经过八年的历程，全新的Leridge™-蓝锐 服装智能制造及管理系统拉开了为中国时尚行业提供服务的序章，“红豆”、“以纯”等服装品牌翘楚成为该系统的首批用户。2021年2月公司成为中国联通产业工业互联网认证供应商。2021年10月，公司完成了Pre-A轮融资，为进一步发展发展壮大奠定了坚实的基础。 </p>
                        <p className="margin-top-24">
                            “客户至上，智诚服务”，本着以客户需求为服务核心的宗旨，北京锐界科技有限公司将为打造全新服装生产制造供应链平台、不断推进时尚制造业技术升级持续倾智奉献。 </p>


                    </Col>
                    <Col className="gutter-row align-left" span={2} xl={12} md={12} sm={24} xs={24}>
                        <div className="imgBox">
                            <img src="/img/aboutus.jpg" alt="" />
                            {/*   <img src="/img/%E5%85%AC%E5%8F%B8-%E7%AE%80%E4%BB%8B1.png" alt=""/>
              <img src="/img/%E5%85%AC%E5%8F%B8-%E7%AE%80%E4%BB%8B2.png" alt=""/>
              <img src="/img/%E5%85%AC%E5%8F%B8-%E7%AE%80%E4%BB%8B3.png" alt=""/>
              <img src="/img/%E5%85%AC%E5%8F%B8-%E7%AE%80%E4%BB%8B4.png" alt=""/>*/}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Introduction;
