import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import { getChildrenToRender } from './utils';
const { location } = window;
function totop(type){
  document.documentElement.scrollTop = document.body.scrollTop =0;
};

function Pricing0(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <div className="padding-24 team">
        <OverPack component={Row} {...dataSource.OverPack}>
          <div className="imgBox">
            <TweenOne
              key="img"
              animation={animType.one}
              resetStyle
              {...dataSource.imgWrapper}
              component={Col}
              componentProps={{
                md: dataSource.imgWrapper.md,
                xs: dataSource.imgWrapper.xs,
              }}
            >
              <span {...dataSource.img}>
                <img src={dataSource.img.children} width="100%" alt="img" />
              </span>
            </TweenOne>
          </div>
          <QueueAnim
            key="text"
            type={animType.queue}
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            {...dataSource.childWrapper}
            component={Col}
            componentProps={{
              md: dataSource.childWrapper.md,
              xs: dataSource.childWrapper.xs,
            }}
          >
            <div class="module-title">
              <span class="underline">我</span>们的团队
              <span className="title-tip">
                TEAM
              </span>
              {
                location.hash=='#/company'?'':
                <div className="go-detail">
                  <a href="#/company" onClick={totop.bind(this)}>了解更多</a>
                  <span className="arrow-right"></span>
                </div>
              }
            </div>

              <p>
                  公司拥有富有创造力的研发团队、技术团队，核心研发人员来自清华，浙大，北理工，大连理工、华中科大等985和211高校和百度、华为、微软一流的互联网技术公司，核心技术团队为来自服装行业、生产工艺等方面的专家。这支充满激情、团结互助的团队为“Leridge™-蓝锐”系统的高效开发和不断升级贡献力量。
              </p>
           {/* <p>我们富有创造力的技术团队来自985和211高校和一流的互联网技术公司：清华，浙大，北理工，大连理工，华中科大，百度，华为，微软。这支充满激情，团结互助的团队高效地开发出核心系统。
              </p>
            <p>  身处作为全球服装生产卓越中心（The Global Center of Excellence）的中国，我们产业团队积聚了行业中教育水平最高，技术能力最强的专家，她们的专业能力覆盖了服装生产工艺，生产自动化和技术培训。</p>*/}
          </QueueAnim>
        </OverPack>
      </div>
    </div>
  );
}

export default Pricing0;
