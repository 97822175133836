import React from 'react';
import { TweenOneGroup } from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row, Col, Divider } from 'antd';

const style = { background: '#0092ff', padding: '8px 0' };

class Milestone extends React.PureComponent {
  getChildrenToRender = (data) =>
  // console.log(data)
    data.map((item) => {
      return (
        <Col key={item.name} {...item}>
          <div {...item.children.wrapper}>
            <span {...item.children.img}>
              <img src={item.children.img.children} alt="img" />
            </span>
          </div>
        </Col>
      );
    });

  render() {


    return (
      <div className="introduction milestone">
        <Row className="row" gutter={24}>
          <Col className="gutter-row left" span={2} xl={12} md={12} sm={24} xs={24}>
            <div class="module-title">
              <span class="underline">里</span>程碑
              <span className="title-tip">
              MILEPOST
              </span>
            </div>
          </Col>
          <Col className="gutter-row align-left" span={2} xl={24} md={24} sm={24} xs={24}>
            <div className="imgBox">
              <img src="/img/milestone2.png" alt=""/>
              {/*<img src="/img/milestone.png" alt=""/>*/}
           {/*   <img src="/img/%E5%85%AC%E5%8F%B8-%E7%AE%80%E4%BB%8B1.png" alt=""/>
              <img src="/img/%E5%85%AC%E5%8F%B8-%E7%AE%80%E4%BB%8B2.png" alt=""/>
              <img src="/img/%E5%85%AC%E5%8F%B8-%E7%AE%80%E4%BB%8B3.png" alt=""/>
              <img src="/img/%E5%85%AC%E5%8F%B8-%E7%AE%80%E4%BB%8B4.png" alt=""/>*/}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Milestone;
