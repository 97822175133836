import React from 'react';
import { Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';





class Banner extends React.PureComponent {
  render() {
    const data = {
      wrapper: { className: 'banner3 banner4 k8ybhr3gpqr-editor_css' },
      textWrapper: {
        className: 'banner3-text-wrapper k8ybk0lbb0s-editor_css',
        children: [
          {
            name: 'slogan',
            className: 'banner3-slogan banner4-slogan k8yaz1nv6ut-editor_css',
            children: '关于我们',
            texty: true,
          },
        ],
      },
    };
    const children = data.textWrapper.children.map((item) => {
      const { name, texty, ...$item } = item;
      if (name.match('button')) {
        return (
          <Button type="primary" key={name} {...$item}>
            {item.children}
          </Button>
        );
      }

      return (
        <div key={name} {...$item}>
          {texty ? (
            <Texty type="mask-bottom">{item.children}</Texty>
          ) : (
            item.children
          )}
        </div>
      );
    });
    return (
      <div {...data.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...data.textWrapper}
        >
          {children}
        </QueueAnim>
      </div>
    );
  }
}
export default Banner;
