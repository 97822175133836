import React from 'react';
import { Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';



// class Banner extends React.PureComponent {
//   render() {
//     const { ...currentProps } = this.props;
//     const { dataSource } = currentProps;
//     delete currentProps.dataSource;
//     delete currentProps.isMobile;
//     const children = dataSource.textWrapper.children.map((item) => {
//       const { name, texty, ...$item } = item;
//       if (name.match('button')) {
//         return (
//           <Button type="primary" key={name} {...$item}>
//             {item.children}
//           </Button>
//         );
//       }

//       return (
//         <div key={name} {...$item}>
//           {texty ? (
//             <Texty type="mask-bottom">{item.children}</Texty>
//           ) : (
//             item.children
//           )}
//         </div>
//       );
//     });
//     return (
//       <div className="newsBanner">
//         <div className="title">公司资讯</div>
//       </div>
//     );
//   }
// }
// export default Banner;



class Banner extends React.PureComponent {
  render() {
    const data = {
      wrapper: { className: 'banner3 k8ybhr3gpqr-editor_css' },
      textWrapper: {
        className: 'banner3-text-wrapper k8ybk0lbb0s-editor_css',
        children: [
          {
            name: 'slogan',
            className: 'banner3-slogan k8yaz1nv6ut-editor_css',
            children: '公司资讯',
            texty: true,
          },
        ],
      },
    };
    const children = data.textWrapper.children.map((item) => {
      const { name, texty, ...$item } = item;
      if (name.match('button')) {
        return (
          <Button type="primary" key={name} {...$item}>
            {item.children}
          </Button>
        );
      }

      return (
        <div key={name} {...$item}>
          {texty ? (
            <Texty type="mask-bottom">{item.children}</Texty>
          ) : (
            item.children
          )}
        </div>
      );
    });
    return (
      <div {...data.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...data.textWrapper}
        >
          {children}
        </QueueAnim>
      </div>
    );
  }
}
export default Banner;
