import React from 'react';
import {Button} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import {isImg} from './utils';

class Banner extends React.PureComponent {
    render() {
        const {...currentProps} = this.props;
        const {dataSource} = currentProps;
        delete currentProps.dataSource;
        delete currentProps.isMobile;
        return (
            <div {...currentProps} {...dataSource.wrapper}>
                {/* <div class="background-box">
            <div class="cover"></div>
            <div class="movebox2">
                <img class="moveimg2" src="/img/banner.jpg" alt="banner"/>
                <div class="moveimg-cover2"></div>
            </div>
            <div class="movebox1">
                <img class="moveimg1" src="/img/banner.jpg" alt="banner"/>
                <div class="moveimg-cover1"></div>
            </div>
        </div> */}
                <QueueAnim
                    key="QueueAnim"
                    type={['bottom', 'top']}
                    delay={200}
                    {...dataSource.textWrapper}
                >
                    {/* <div class="movebox2">
              <img class="moveimg2" src="url(/img/banner.jpg)" alt="banner">
              <div class="moveimg-cover2"></div>
          </div>
          <div class="movebox1">
              <img class="moveimg1" src="url(/img/banner.jpg)" alt="banner">
              <div class="moveimg-cover1"></div>
          </div> */}
                    <div key="title" className="banner-content">
                        Leridge™-蓝锐
                    </div>
                    <div key="title" className="banner-content">
                        服装智能制造及管理系统
                    </div>
                    <div key="content" className="banner-tip">
                        微单调控<span class="plus-mark">+</span>即时灵动<span class="plus-mark">+</span>公平用长<span class="plus-mark">+</span>远程管控<span class="plus-mark">+</span>透明高效
                    </div>
                </QueueAnim>
                <TweenOne
                    animation={{
                        y: '-=20',
                        yoyo: true,
                        repeat: -1,
                        duration: 1000,
                    }}
                    className="banner0-icon"
                    key="icon"
                >
                    <DownOutlined />
                </TweenOne>
            </div>
        );
    }
}

export default Banner;
