import React from 'react';
import { TweenOneGroup } from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from './utils';
import { Row, Col, Divider } from 'antd';

const style = { background: '#0092ff', padding: '8px 0' };

class Gutter extends React.PureComponent {
  getChildrenToRender = (data) =>
  // console.log(data)
    data.map((item) => {
      return (
        <Col key={item.name} {...item}>
          <div {...item.children.wrapper}>
            <span {...item.children.img}>
              <img src={item.children.img.children} alt="img" />
            </span>
          </div>
        </Col>
      );
    });

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    const childrenToRender = this.getChildrenToRender(
      dataSource.block.children
    );
    const brandList=["恩卓","芳姿菲影","观月","方片城亿","兰地","乐而","丽波","梦思兰","天瑪时","清华志清国际","情爱的白","北京群邑","水淼服饰","万物生长","尚绱","乌兰","希努尔","雅派朗迪","一芮纳纪","依睐","友涵诚衣","爱世普兰","子午线","依泽锦","三利","爱喜德","五色风马","大华"]
    const colChildren=dataSource.block.children.map((item)=>
      <Col className="gutter-row" span={2} xl={2} md={4} sm={8} xs={12}>
        <div className="gutter-box">
          <img src={item.children.img.children} alt="img" />
    <div className="cover">{item.children.name}</div>
        </div>
      </Col>
    )
    const colChildren1=brandList.map((item)=>
      <Col className="gutter-row" span={2} xl={2} md={4} sm={8} xs={12}>
        {/* <div className="gutter-box"> */}
          <span>{item}</span>
          {/* <div className="cover">有沐</div> */}
        {/* </div> */}
      </Col>
    )
    return (
      <div className="gutter">
        <div class="module-title">
          <span class="underline">合</span>作品牌
          <span className="title-tip">
          CLIENT
          </span>
        </div>
         <Row className="row" gutter={24}>
          {colChildren}
          {colChildren1}
        </Row>
      </div>
    );
  }
}

export default Gutter;
