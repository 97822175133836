/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';


import Banner from './Banner';
import News from './News';
import NewsDetail from './NewsDetail';
import Nav3 from '../Home/Nav3';
import {
  Banner30DataSource
} from '../Serve/data.source';
import {
  Nav30DataSource
} from '../Home/data.source';
import './less/antMotionStyle.less';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
    document.getElementsByTagName('body')[0].scrollTop = 0;
  }

  render() {
    
    let children=[]
    console.log("dddddddddddddddddddddd")
    let id =location.href.split("?id=")[1];
    console.log(id)
    if(id){
      children = [
        <Nav3
              id="Nav3_0"
              key="Nav3_0"
              dataSource={Nav30DataSource}
              isMobile={this.state.isMobile}
            />,
        <NewsDetail/>
      ];
    }else{
      children = [
        <Nav3
              id="Nav3_0"
              key="Nav3_0"
              dataSource={Nav30DataSource}
              isMobile={this.state.isMobile}
            />,
        <Banner
          id="Banner3_0"
          key="Banner3_0"
          dataSource={Banner30DataSource}
          isMobile={this.state.isMobile}
        />,
        <News/>
      ];
    }
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
