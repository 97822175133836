import React from 'react';

export const NewsData = {
    list: [
        /*{
            img: "/img/20221001.jpeg",
            title: '"锐界科技"祝福祖国生日快乐，繁荣昌盛！',
            time: "2022年10月1日",
            content: [],
            id: 20221001
        },*/
        {
            img: "/img/20221012.jpeg",
            title: "牵手无锡刘潭集团，“蓝锐”系统精进再向前",
            time: "2022年10月12日",
            content: [
                '金秋九月，收获的季节，北京锐界科技公司与无锡刘潭集团下属无锡威鹰时装有限公司正式签订了合作协议：“威鹰时装”公司委托“锐界科技”，以其研发的Leridge™-蓝锐服装智能制造及管理系统（以下简称“蓝锐”系统）对“威鹰时装”公司现有生产管理系统、生产方式、生产布局等进行改造升级。',
                '刘潭集团成立于1992年，总部位于江苏无锡，生产面积10万平米，在安徽亳州、江苏泗洪、柬埔寨都设有生产基地，分别生产居家服，童装，针织服装，衬衫，休闲服装，居家用品等，目前已有45条精益化推动式单件流水线，员工3000余人',
                '今年五月，拥有二十多年服装OEM贴牌加工经验的刘潭集团钱董事长找到了北京锐界科技有限公司，希望了解“蓝锐”系统的相关详情，以便为刘潭集团生产智能化再次改进做准备。',
                '接到邀请后，“锐界科技”组织项目、研发、产品的同事组成专项项目小组，由CEO金勇作为项目架构师带队，与刘潭集团组织的项目小组开始对接。顶着新冠疫情带来沟通不便，通过初期两个团队的多次交流，双方最终确认无锡威鹰时装有限公司的升级改造项目将分资料准备、蓝图汇总、正式实施等阶段进入工作进程。',
                '无锡威鹰时装有限公司生产物理空间由一栋五层楼建筑构成，整体约4000平方米，改造面积涉及四个楼层，以及前道裁剪后道入库等生产前、后道相关工序，约3000平方米。近80个工位分布在核心的700多平方米内。',
                ' 从六月到八月，双方工作人员汇总项目资料、查阅历史数据分析整理需求定义、反复整理项目需求和项目问题点，不断讨论项目目标与实施路径的差异性和最终的可实现性。以求制定出完整的方案蓝图和落地实施方案。在这个过程中，威鹰时装有限公司工作团队充分了解了“蓝锐”系统的先进性，也从自身需求的角度，对系统提出了建议和意见，希望在实施过程中更加符合此次项目的需求。北京锐界科技公司工作团队在与威鹰时装有限公司生产现场管理人员、工人、IT技术部门人员沟通中，将系统词典包括款式样式、工艺术语、工种大类、专机术语、财务报表等量身定做，和“威鹰”原有相关系统进行对接，实时同步。在这个过程中，“锐界科技”同时也不断在提升系统与千变万化的服装生产形态的适配度，使“蓝锐”系统的引领性更加广泛适用于服装行业智能生产领域。',
                '方案和蓝图阶段耗时近三个月，但磨刀不误砍柴工，目前，无锡威鹰时装有限公司的升级改造项目已经进入实施阶段，一方面系统环境准备、用户权限设置、静态数据导入与检查等步骤已经在有条不紊的进行中，一方面“锐界科技”工作团队开始全面给威鹰时装有限公司管理层、IE等培训“蓝锐”系统的使用。另一方面，刘潭集团相关负责人已经开始和“锐界科技“CEO金勇先生商讨下一个智能制造车间改造项目的布局与方案。',
                '相信在不久的十一月，随着无锡威鹰时装有限公司生产车间智能升级改造完成进入正常生产运段，刘潭集团下一个智能制造车间改造项目也将正式拉开帷幕。',
                '此次，北京锐界科技有限公司不仅又成功地服务了一个服装行业的翘楚企业，更是积攒了一批经验值，为Leridge™-蓝锐服装智能制造及管理系统的再升级打下基础。'
            ],
            id: 20221012
        },
        {
            img: "/img/20220923.jpg",
            title: "初访江苏南京华瑞时尚集团",
            time: "2022年9月23日",
            content: [
                '9月15日，北京锐界科技有限公司CEO金勇、生产管理负责人陈补东在“锐界科技”江苏分站负责人的陪同下，拜访参观了江苏华瑞时尚集团下属“华瑞标样厂',
                '华瑞时尚集团创办于1993年，总部位于古都南京，是一家大型投资控股跨国民营企业，旗下拥有服装股份、针纺织科技等五大核心事业体系。在南京、上海、安徽等地及海外柬埔寨、越南建有工厂，构建了完整的供应链体系。',
                '钱总和赵先生对“锐界科技”的Leridge™-蓝锐服装智能制造及管理系统表示了极大的兴趣，现场详细了解了系统核心功能、生产效率提高的原理和比例、工人合理分工等，金总和陈补东分别进行了解答。',
                '在整个参观过程中，钱总也向金总介绍了“华瑞时尚”对于生产智能化改造的需求，希望从江宁的“华瑞标样厂”试点改造，尝试找到一种适合“华瑞时尚”当下生产需求的智能制造生产新方式。钱总表示公司自成立以来，一直以强有力的供应链体系为荣，所以，在当下的新形式下，公司的决心非常大，势必要在供应链体系上更上一层楼，现在已经新建厂房三栋，用于全新智能柔性生产车间打造，希望和各方面能够提供智能制造管理及服务改造的公司精诚合作，完成既定目标。',
                '参观结束后，在钱总的安排下，“华瑞标样厂”第一时间提供了工厂CD图图纸，希望“北京锐界”提供Leridge™-蓝锐服装智能制造及管理系统支持下的智能工厂改造方案。'
            ],
            id: 20220923
        },
        {
            img: "/img/20220916.jpg",
            title: "横向联动，强强谋划",
            time: "2022年9月16日",
            content: [
                '中秋节前，应北京云迹科技有限公司的邀请，“锐界科技”由首席行业顾问王茁带队，研发负责人张文杰、产品负责人王瑧等访问了北京云迹科技有限公司，调研其AMR研发生产及应用情况，为公司AMR采购拓宽渠道。',
                '北京云迹科技有限公司是国内专注商用机器人产品应用与技术研发的创新型科技公司，主要研发生产系列应用型机器人。此次邀约，是基于北京锐界科技公司Leridge™-蓝锐服装智能制造系统中作为传输载体大量使用的AMR，与云迹科技机器人通用智能移动平台系列产品的功能相近，故而希望通过考察交流，探讨双方合作的可能性。',
                '在“云迹科技“战略合作总监Cheryl及两位技术人员的陪同下，“锐界科技”一行边参观边了解“云迹科技”的系列产品，两位技术人员根据应用场景对相应产品做了介绍。在参观过程中，“锐界科技”针对服装生产场景中AMR使用需要进行了一些细节问题沟通，比如运行速度、最窄通行宽度、避障能力、转弯半径、货架搬运效率等。',
                '在交流中，“锐界科技”也向“云迹科技”系统介绍了Leridge™-蓝锐服装智能制造系统的发展前景以及相关AMR的应用场景，Cheryl及两位技术人员后续会针对“锐界科技”所需AMR使用场景做针对性开发。',
                '拜访结束后，产品负责人王瑧整理出二十条“云迹科技”调研的结果与问题，为与其进一步的交流和合作打下了基础。'
            ],
            id: 20220916
        },
    /*    {
            img: "/img/20220909.jpeg",
            title: "登峰看月，中秋团圆",
            time: "2022年9月9日",
            content: [],
            id: 20220909
        },*/
        {
            img: "/img/20220902.jpg",
            title: "为服装“灯塔工厂”服务，“锐界科技”年中会定目标",
            time: "2022年9月2日",
            content: [
                '9月1日，开学时间，北京锐界科技有限公司的小伙伴们也迎来了自己新的“开学季”——全国各地的小伙伴们回到北京总部，济济一堂，总结上半年的收获，开启下半年的征程。',
                '“锐界科技”全员年中会由CEO金勇主持。他首先肯定了上半年所有“锐界”同仁的积极付出，尤其在新冠疫情带来的不确定性的大环境下，锐界科技仍取得了客户的信任和业界的认同，金总表达了因此而产生的自豪感和对公司全员的感谢之情。',
                '在接下来的议程里，“锐界科技”项目部同事梳理总结了上半年服装、服饰企业重点客户使用、参观、洽谈 “Leridge™-蓝锐服装智能制造及管理系统”的情况，同时也详细介绍了下半年对每位客户跟进服务的时间进度与相应的执行方案。“锐界科技”研发部同事完成了“Leridge™-蓝锐服装智能制造及管理系统”的再次升级，本次升级，包括底层架构的重构、管理数据模块优化等，使得“蓝锐”系统更加友好，能够激发客户的使用热情。“锐界科技”市场部同事列举了上半年市场基础工作完成的各个项目，同时介绍了未来一年市场拓展的原则和方式，即以“一区域一展会N重点”的方式逐渐渗入服装服饰产业，在建立有序拓展市场的基础上，加持服装服饰业对“Leridge™-蓝锐服装智能制造及管理系统”——服装智能制造业新黑马的认知。',
                '会议最后，金总总结，2022年下半年“锐界科技”要建立健全有效的销售体系和销售团队，更要继续加大研发力度，以用户视角用户思维，不断拓展研发思路，争取为中国服装制造业“灯塔工厂”的出现尽一份绵薄之力。',
                '全员会议结束后，研发专项小组会、产品专项小组会继续讨论。9月2日下午，“锐界科技”全员年中会在外地同事依依不舍的道别中全满结束。'
            ],
            id: 20220902
        },
        {
            img: "/img/20220827.jpg",
            title: "“蓝锐系统“随中国联通服装制造军团，亮相中国服装科技大会",
            time: "2022年8月27日",
            content: [
                '8月25日-26日，由中国服装协会主办、杭州临平区人民政府主办的中国服装科技大会在杭州临平艺尚雷迪森广场酒店举办。中国联合网络通信有限公司浙江分公司作为支持单位，全面参与了此次会议。',
                '作为中国联合网络通信有限公司产业军团战略布局中，服装制造军团版块在服装智能制造方面的合作商，北京锐界科技有限公司应中国联合网络通信有限公司浙江分公司的邀请，配合此次大会，在中国服装科创研究院展厅模拟展示了Leridge™-蓝锐服装智能制造系统的工作场景。',
                '25日上午，中国服装科技大会全体会议中，中国联通大数据首席科学家范济安在题为“5G+工业互联网助力服装行业数字化转型发展“的主题发言中，以成功使用“蓝锐”系统的以纯数字化工厂为例，向与会的中国服装企业代表介绍了中国联通服装制造军团在5G+工业互联网的进程、成果与未来发展方向。',
                '下午，浙江省联通公司副总虞允纲、联通服装军团副军团长樊伟、中国联通大数据首席科学家范济安一行在北京锐界科技有限公司CEO金勇陪同下，参观了Leridge™-蓝锐服装智能制造系统的工作场景。北京锐界科技有限公司生产管理负责人周洲详细介绍了“蓝锐“系统现场的实际应用情况，各位领导和专家在现场一起沟通交流，为“蓝锐”系统的未来和持续发展提出了宝贵的意见和建议。',
                '26日上午，中国服装科技大会的与会代表在会后集体参观了中国服装科创研究院展厅模拟展示了Leridge™-蓝锐服装智能制造系统的工作场景。北京锐界科技有限公司同事在现场为企业代表答疑解惑。',
                '为期两天的会议与展示，让参加中国服装科技大会的中国服装企业代表初步认识了北京锐界科技有限公司的Leridge™-蓝锐服装智能制造系统，北京锐界科技有限公司在和专家、同行、企业的交流中，也收获颇多，增强了为服装行业数字化转型、智能制造服务的信心。'
            ],
            id: 20220827
        },
        {
            img: "/img/20220819.jpg",
            title: "AI+文创，“锐界“受邀参加全球AI文创大赛（GAAC）做专题演讲",
            time: "2022年8月19日",
            content: [
                '随着人工智能技术在全球范围内的迅速兴起和发展，为更好地促进人工智能在文创、艺术等领域的应用，推动文创行业产业链升级，清华大学文化创意发展研究院、清华大学人工智能研究院、清华大学艺术与科技创新基地、艺评网、英诺天使基金、佛山市南海区西樵山书院等共同发起了“全球AI文创大赛（GAAC）”，于2022年8月4-5日在广东佛山南海千灯湖创投小镇举办，该大赛旨在通过AI技术与文创、艺术创作的跨界融合，挖掘AI文创的全面落地场景，赋能新文创，激发新活力。',
                '北京锐界科技有限公司应主办方邀请，于8月5日在该活动“AI文创发展论坛上”进行了题目为“AI技术运用于服装智能制造“的主旨分享。公司首席行业专家王茁，在分享中向与会嘉宾和参赛各项目负责人，介绍了行业当下服装智能制造的发展状况以及未来发展趋势，讲解了“Leridge-蓝锐服装智能制造管理系统”对传统服装生产带来的变革。会后，参加会议的纺织服装行业与会代表对“蓝锐系统”打破传统产线强链接生产方式改为虚拟产线生产方式的创新，表示出极大兴趣，王总对他们提出的相关问题进行了解答。'
            ],
            id: 20220819
        },
        {
            img: "/img/20220812.jpg",
            title: "“以纯“分享，“蓝锐”助力",
            time: "2022年8月12日",
            content: [
                '8月10日-11日，“稳预期·增动能·促发展”2022年工业稳增长政策宣讲暨产业对接活动在东莞举办。东莞以纯集团有限公司应邀在企业分享环节，介绍了其丽远工厂采用北京锐界科技有限公司的“蓝锐服装智能制造管理系统”数字化转型的成功经验。',
                '本次活动由东莞市工业和信息化局、东莞市人力资源和社会保障局、东莞市商务局、东莞市金融工作局、国家税务总局东莞市税务局主办。',
                '东莞工业基础好，产业链供应链发达，工业韧性强，前期政策效应正在释放。东莞市工业和信息化局党组书记、局长肖必良表示，希望与会企业进一步认清当前发展形势，扎根东莞，坚定信心，加强合作，加快数字化转型，实现更稳健、更高质量的发展，为东莞工业稳增长作出贡献。',
                '以纯集团副董事长蓝岸在11日下午的会议上，面对120余家相关企业的与会代表，做了题为“以数字化促进传统服装企业转型”的专题演讲。演讲由数字化进程是服装行业大势所趋、智能“智”造为服装制造的未来保驾护航、以纯集团“丽远”智能化工厂介绍等三个方面构成。',
                '蓝董着重分享了以纯集团丽远工厂自2021年6月起，引进北京锐界科技有限公司的“Leridge™-蓝锐服装智能制造管理系统”进行数字化改造以来取得的进展。以人工智能和机器人为核心的“蓝锐系统”的虚拟产线技术，在完成车间环境布局改造试运营三个月即见成效，为丽远工厂实现了产值提升30%的预期生产目标。效益提升、管理成本减少、员工工资增加、员工留存率提升也即时可见。2021年12月丽远工厂数字化转型项目荣获工信部“2021中国5G+工业互联网典型应用”。2022年1月，新华社以题为“5G进工厂，看服装企业转型的“智造”突围”对丽远工厂进行了专题报道。',
                '应对消费市场的变化，服装企业生产供应链如何运用数字化转型完成生产模式的改变、并进而适应商业模式和企业管理方式的变革，是当今服装品牌的重要发展方向。积极推进数字化转型，制造端通过智能改造升级供应链体系首当其冲，如何具备柔韧兼具的生产制造体系，使其成为精确数据化、高度智能化和极强可靠性的品牌发展依托，是服装品牌永续经营必要条件。据不完全统计，今后10年，服装全行业生产数字化转型的技改投入可达650亿元。北京锐界科技有限公司的“Leridge™-蓝锐服装智能制造管理系统”正是在这样背景下应运而生，赋能传统制造业数字化转型，为传统服装制造业带来即时可见的转变，助力中国服装品牌永续经营。'
            ],
            id: 20220812
        },
        {
            img: "/img/2022081202.jpg",
            title: "参观CISMA，“锐界”科技蓄势待发",
            time: "2022年8月12日",
            content: [
                '7月29日-8月1日，主题为“智造 智能 智汇”的CISMA 2021中国国际缝制设备展在宁波举办。',
                '中国国际缝制设备展览会（CISMA）是全球最大的专业缝制设备展览会，展品包含了缝前、缝制、缝后各类机器以及CAD/CAM设计系统及面辅，完整地展现了缝制服装的整个链条。',
                '北京锐界科技有限公司首席行业专家王茁及市场部同事参观了展会，重点关注服装智能产业领域同业的发展情况，如宁波瑞晟智能科技公司、杰克股份有限公司、智千科技等企业。',
                '7月29日至8月1日，三天半的展期，两万多人共计五万多人次，参观参加CISMA，揽胜智慧缝制场景，共赏科技与缝纫的美妙融合。',
                '北京锐界科技有限公司将厉兵秣马，出击下届CISMA，为科技与缝纫增添绚丽色彩。'
            ],
            id: 2022081202
        },
        {
            img: "/img/20220805.jpg",
            title: "“锐界”布局未来，Leridge™-蓝锐系统亮相河南服装行业",
            time: "2022年8月5日",
            content: [
                '7月25日-27日，北京锐界科技有限公司CEO金勇、首席行业专家王茁一行到访河南，展开为期三天的市场考察。',
                '河南作为中国纺织服装业新兴代表大省，近几年大力推动服装制造业全速发展，全省一百多个区县已有二十多个县建立了不同规模的服装生产加工园区，不同程度承接沿海及江浙一带服装制产能的转移。在做好承接产能的同时，河南新建服装工厂智能化步伐的超前也成为其重要的特色之一，阳光集团、盛泰纺织、乔治白制衣、阿尔本制衣等中国纺织服装的一些龙头企业纷纷在河南布局新型智能化工厂。',
                '河南省服装协会会长李刚、副秘书长李文杰向金总、王总介绍了郑州当地龙头企业雅丽达、逸阳等品牌的发展情况，王总向李会长介绍了Leridge™-蓝锐系统的核心竞争力，双方就推动Leridge™-蓝锐服装智能制造及管理系统进一步在河南的应用达成共识。',
                '在接下来的行程中，金总、王总到访河南逸阳服饰有限公司，向生产负责人张茂念厂长、IT部门负责人盛军介绍了Leridge™-蓝锐服装智能制造及管理系统的情况，对方对系统的先进性表示认可，也吃惊于系统对传统服装生产模式带来的颠覆性改变。',
                '在商水县，金总、王总在县委书记、县长、河南服装协会李刚会长等的陪同下参观了“阿尔本”、“盛泰”等大型服装工厂，并进行座谈，探讨商水县大力发展服装智能制造产业的未来。',
                '为期三天的考察参观，将为北京锐界科技有限公司未来在河南市场的布局奠定基础。'
            ],
            id: 20220805
        },
        {
            img: "/img/%E8%B5%84%E8%AE%AF-img1.png",
            title: "以纯项目一期顺利完成验收",
            time: "2021年8月",
            content: [],
            id: 1
        },
        {
            img: "/img/%E8%B5%84%E8%AE%AF-img2.png",
            title: "成为以纯、超盈、上海国利和红豆的合作方",
            time: "2021年1月",
            content: [],
            id: 2
        },
        {
            img: "/img/%E8%B5%84%E8%AE%AF-img3.png",
            title: "经认证成为中国联通供应商",
            time: "2021年1月",
            content: [],
            id: 3
        },
        {
            img: "/img/%E8%B5%84%E8%AE%AF-img4.png",
            title: "上海时装智能制造展顺利闭幕",
            time: "2020年12月",
            content: [],
            id: 4
        },
        {
            img: "/img/%E8%B5%84%E8%AE%AF-img5.png",
            title: "锐界科技亦庄工厂荣获“北京经济技术开发区2019年度智能制造试点示范企业”",
            time: "2019年9月",
            /* content: "根据北京经济技术开发区2019年度重点工作要求，经企业申报，第三方初审，专家评议复审后，锐界科技亦庄工厂入选”北京经济技术开发区2019年度智能制造试点示范企业”。 ",*/
            content: [
                '根据北京经济技术开发区2019年度重点工作要求，经企业申报，第三方初审，专家评议复审后，锐界科技亦庄工厂入选”北京经济技术开发区2019年度智能制造试点示范企业”。'
            ],
            id: 5
        },
        {
            img: "/img/%E8%B5%84%E8%AE%AF-img6.png",
            title: "锐界科技南通工厂正式投入使用",
            time: "2019年7月",
            /*  content: "2019年7月，经过半个月对场地的装修改造，锐界科技江苏南通工厂正式投入使用，这是在北京试点成功实现新型生产模式的伟大成果，南通工厂场地的总面积达到4000平方米，生产规模达到200个工位。Maggy London成为第一个国际品牌合作伙伴。",*/
            content: [
                '2019年7月，经过半个月对场地的装修改造，锐界科技江苏南通工厂正式投入使用，这是在北京试点成功实现新型生产模式的伟大成果，南通工厂场地的总面积达到4000平方米，生产规模达到200个工位。Maggy London成为第一个国际品牌合作伙伴。'
            ],
            id: 6
        },
        {
            img: "/img/%E8%B5%84%E8%AE%AF-img7.png",
            title: "锐界科技南通工厂 入选“2018中国B2B行业新锐势力榜”",
            time: "2018年12月",
            /*    content: "在由宁波市经信委、宁波市商务委、宁波市口岸打私办、宁波市鄞州区人民政府主办，托比网、鄞州区电子商务协会承办的“第五届中国产业互联网大会暨宁波产业发展论坛”上颁发了“2018中国B2B行业新锐势力榜”。恭喜锐界科技南通工厂入榜。",*/
            content: [
                '在由宁波市经信委、宁波市商务委、宁波市口岸打私办、宁波市鄞州区人民政府主办，托比网、鄞州区电子商务协会承办的“第五届中国产业互联网大会暨宁波产业发展论坛”上颁发了“2018中国B2B行业新锐势力榜”。恭喜锐界科技南通工厂入榜。'
            ],
            id: 7
        },
        {
            img: "/img/%E8%B5%84%E8%AE%AF-img8.png",
            title: "荣获南通·浙江大学校友创业大赛暨2018南通开发区“能达杯”创新创业大赛 第二名",
            time: "2018年10月",
            /*       content: "恭喜锐界科技南通工厂荣获：南通·浙江大学校友创业大赛暨2018南通开发区“能达杯”创新创业大赛 第二名。",*/
            content: [
                '恭喜锐界科技南通工厂荣获：南通·浙江大学校友创业大赛暨2018南通开发区“能达杯”创新创业大赛 第二名。'
            ],
            id: 8
        },
        {
            img: "/img/%E8%B5%84%E8%AE%AF-img9.png",
            title: "北京亦庄智能制造试点建成",
            time: "2018年3月",
            /*   content: "2018年3月，锐界科技在亦庄经济技术开发区建成首个智能制造试点，完成了智能制造柔性供应链由0到1的突破。真正实现了从技术模拟试验到落地实际生产的转变。试点场地总面积共1100平方米，共设30个工位，使用30台机器人。裂帛成为第一个品牌合作伙伴。",*/
            content: [
                '2018年3月，锐界科技在亦庄经济技术开发区建成首个智能制造试点，完成了智能制造柔性供应链由0到1的突破。真正实现了从技术模拟试验到落地实际生产的转变。试点场地总面积共1100平方米，共设30个工位，使用30台机器人。裂帛成为第一个品牌合作伙伴'
            ],
            id: 9
        }
    ]
}
