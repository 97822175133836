import React from 'react';
import {Button} from 'antd';
import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import Nav3 from '../Home/Nav3';
import {enquireScreen} from 'enquire-js';
import {
    NewsData
} from './data.source';
import {
    Nav30DataSource
} from '../Home/data.source';

const {location} = window;
const dataList = NewsData.list
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

let data = {}

class NewsDetail extends React.PureComponent {
    // constructor(props){
    //   // dataList.map(item=>{
    //   //   if(item.id==id){
    //   //     data=item
    //   //   }
    //   // })
    //   console.log("dddddddddddd")
    //   // this.setState({
    //   // });
    // }

    constructor(props: IProps) {
        super(props)
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
        };
        let id = location.href.split("?id=")[1];
        dataList.map(item => {
            if (item.id == id) {
                data = item
            }
        })
        console.log(dataList)
        console.log(data)
    }

    componentDidMount() {
    }

    render() {
        const space = '2em'
        // <div className="content" style={{textIndent:space}}>{item}</div>

        const contentChildren = data.content.map((item) =>
            <div className="content">{item}</div>
        )
        return (
            <div>
                {/* <Nav3
            id="Nav3_0"
            key="Nav3_0"
            dataSource={Nav30DataSource}
            isMobile={this.state.isMobile}
          /> */}
                <div className="newsDetail">
                    <div className="title">{data.title}</div>
                    <div className="time">{data.time}</div>
                    {/*<div className="content">{data.content}</div>*/}
                    {contentChildren}
                    <img src={data.img} alt="" />
                </div>
            </div>
        );
    }
}

export default NewsDetail;
