import React from 'react';
import TweenOne from 'rc-tween-one';
import {Row, Col} from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import {getChildrenToRender} from './utils';

class Content7 extends React.Component {
    getBlockChildren = (data) =>
        data.map(($item) => {
            const {...item} = $item;
            const {title, img, content} = item;
            ['title', 'img', 'content'].forEach((key) => delete item[key]);
            return (
                <li key={item.name} {...item}>
          <span {...img}>
            <img src={img.children} width="100%" alt="img" />
          </span>
                    <h2 {...title}>{title.children}</h2>
                    <div {...content}>{content.children}</div>
                </li>
            );
        });

    totop(type) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
    };


    render() {
        const {...props} = this.props;
        const {dataSource, isMobile} = props;
        delete props.dataSource;
        delete props.isMobile;
        const ulChildren = this.getBlockChildren(dataSource.block.children);
        const queue = isMobile ? 'bottom' : 'left';
        const imgAnim = isMobile
            ? {
                y: 30,
                opacity: 0,
                delay: 600,
                type: 'from',
                ease: 'easeOutQuad',
            }
            : {
                x: 30,
                opacity: 0,
                type: 'from',
                ease: 'easeOutQuad',
            };
        return (
            <div {...props} {...dataSource.wrapper}>
                <div className="padding-24">
                    <OverPack {...dataSource.OverPack} component={Row}>
                        <QueueAnim
                            key="text"
                            type={queue}
                            leaveReverse
                            ease={['easeOutQuad', 'easeInQuad']}
                            {...dataSource.textWrapper}
                            component={Col}
                        >
                            <div class="module-title">
                                <span class="underline">走</span>进锐界
                                <span className="title-tip">
                ABOUT Leridge™
                </span>
                                <div className="go-detail">
                                    <a href="#/company" target="_self" onClick={this.totop.bind(this)}>了解更多</a>
                                    <span className="arrow-right"></span>
                                </div>
                            </div>
                            {/*  <p className="align-left">
                  北京锐界科技有限公司成立月2014年3月，注册资金600万元，是一家自主研发智能AI和AMR机器人驱动系统应用于建设全球时装柔性智能供应链的高新科技类公司。
              </p>
                <p  className="align-left margin-top-24">公司研发的柔性智能制造技术，拥有中国、美国和印度的发明专利，采用全球首创智能AI 和 AGV机器人赋能的智能矩阵柔性制造技术，以助力时装企业打造全平台的柔性供应链管理体系和实现时装全产业链数字化为目标。</p>
             */}

                            <p className="align-left">
                                北京锐界科技有限公司是基于自主学习型人工智能和机器人技术，专注于时尚行业的智能制造系统提供商。                            </p>
                            <p className="align-left margin-top-24">
                                公司成立于2014年3月，总部位于北京。经过四年多的自主研发，公司于2018年推出了第一代基于自主学习型人工智能和机器人技术的智能制造系统，于2020年5月，研发完成了AI优化智能制造系统3.0版上线，即：Leridge™ -蓝锐 服装智能制造及管理系统。其核心的“并行制造系统和方法”分别获得了中国、美国和印度取得了专利权。 </p>
                        </QueueAnim>
                        <TweenOne
                            key="img"
                            animation={imgAnim}
                            resetStyle
                            {...dataSource.img}
                            component={Col}
                        >
                            <img className="about-img" src={dataSource.img.children[0]} width="100%" alt="img" />
                            {/*<img className="about-img" src={dataSource.img.children[1]} width="100%" alt="img" />
              <img className="about-img" src={dataSource.img.children[2]} width="100%" alt="img" />*/}
                        </TweenOne>
                    </OverPack>
                </div>
            </div>
        );
    }
}

export default Content7;
