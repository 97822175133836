//我们的优势
import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from './utils';
const { location } = window;
class Advantage extends React.PureComponent {

  totop(type){
    document.documentElement.scrollTop = document.body.scrollTop =0;
  };


  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const {
      wrapper,
      titleWrapper,
      page,
      OverPack: overPackData,
      childWrapper,
    } = dataSource;

    const data=[
      {
        img:"/img/sopt-icon1.png",
        title:"高效率",
        content:"消灭生产线失衡和随机损耗，模块化产品设计和制造，数据驱动的智能优化。"
      },
      {
        img:"/img/sopt-icon2.png",
        title:"超快反",
        content:"即时生产，实时虚拟生产线，智能技能管理系统缩短学习曲线，工艺数字化和智能化。"
      },
      {
        img:"/img/sopt-icon3.png",
        title:"柔性生产",
        content:"并行生产，同时生产多种时装款式，可以实现全类别，一站式服务。"
      },
      {
        img:"/img/sopt-icon4.png",
        title:"规模化",
        content:"ACS (Autonomous Couture System) 智能化自动生产管理，云智造，全产业链透明。"
      },
      {
        img:"/img/sopt-icon5.png",
        title:"客户体验",
        content:"按天交货，小批量也能有大批量价格，一站式服务，透明供应链管理。"
      },
    ]

    const colChildren=data.map((item)=>
      <Col className="gutter-row " span={4} xl={4} md={4} sm={12} xs={24}>
        <img className="img-star" src="/img/star.png" alt=""/>
        <img className="img" src={item.img} alt=""/>
        <div class="title">{item.title}</div>
        <div class="content">{item.content}</div>
      </Col>
    )

    return (
      <div {...props} {...wrapper}>
        <div className="advantageBox ">
          <div {...page}>
            <div class="module-title">
              <span class="underline">我</span>们的优势
              <span className="title-tip">
                TECH SPOTLIGHT
              </span>
              {
                (location.hash=='#/serve'||location.hash=='#/company')?'':
                <div className="go-detail">
                  <a href="#/serve" onClick={this.totop.bind(this)} target="_self">了解更多</a>
                  <span className="arrow-right"></span>
                </div>
              }
            </div>
        
            <Row className="row advantage" gutter={24}>
              {colChildren}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Advantage;
