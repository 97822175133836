import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper k8u3c0srvm-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      '/img/logo-top.png',
  },
  Menu: {
    className: 'header3-menu k8u3bt97n89-editor_css',
    children: [
      {
        name: 'item4',
        className: 'header4-item',
        children: {
          href: '#/home',
          children: [
            {
              children: (
                <span>
                  <p>
                    <span>首页</span>
                    <br />
                  </p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item~k8y3dlp3zn',
        className: 'header4-item',
        children: {
          href: '#/news',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>新闻</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item~k8y3dmv3k1q',
        className: 'header4-item',
        children: {
          href: '#/serve',
          children: [
            {
              children: (
                <span>
                  <p>服务</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item~k8y3dnmd13k',
        className: 'header4-item',
        children: {
          href: '#/company',
          children: [
            {
              children: (
                <span>
                  <p>公司</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      // {
      //   name: 'item~k8y3doerbl',
      //   className: 'header4-item k8y3m4vhc8-editor_css',
      //   children: {
      //     children: [{ children: '中文', name: 'text' }],
      //     className: 'k8y3oofrlac-editor_css',
      //   },
      // },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0 k8y41om2jo-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper k8y41kl9re9-editor_css' },

  content: {
    className: 'banner0-content k8y43i694on-editor_css',
    children: (
      <span>
        <span>
          <h3>时装零售新时代</h3>
        </span>
      </span>
    ),
  },
  button: {
    className: 'banner0-button',
    children: (
      <span>
        <p>并 行 + 高 效 + 柔 性 + 即 时 + 智 能</p>
      </span>
    ),
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      '/img/couturetechnews.jpg',
    className: 'k8xxz9mvryt-editor_css',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title k8xy28zonsi-editor_css',
    children: (
      <span>
        <span>
          <h3>以纯项目一期顺利完成验收</h3>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
        </p>
      </span>
    ),
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6 k8y4ku42nel-editor_css' },
  textWrapper: { className: 'content6-text', xs: 24, md: 12 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>关于我们</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>
      北京锐界科技有限公司成立月2014年3月，注册资金600万元，是一家自主研发智能AI和AMR机器人驱动系统应用于建设全球时装柔性智能供应链的高新科技类公司。
      </p>
          <p>公司研发的柔性智能制造技术，拥有中国、美国和印度的发明专利，采用全球首创智能AI 和 AGV机器人赋能的智能矩阵柔性制造技术，以助力时装企业打造全平台的柔性供应链管理体系和实现时装全产业链数字化为目标。</p>
          </span>
        ),
      },
    ],
  },
  img: {
    children:
      [
     /*   '/img/aboutus1.jpg',
        '/img/aboutus2.jpg',*/
        '/img/aboutus.jpg'
      ],
    className: 'content6-img',
    xs: 24,
    md: 12,
  },
  block: {
    children: [
    ],
  },
};
export const Content40DataSource = {
  wrapper: { className: 'home-page-wrapper content4-wrapper' },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '蚂蚁金融云提供专业的服务',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content content4-title-content',
        children: '科技想象力，金融创造力',
      },
    ],
  },
  video: {
    className: 'content4-video',
    children: {
      video:
        '/video/%E9%85%B7%E6%8B%93%E5%AE%A3%E4%BC%A0%E8%A7%86%E9%A2%91-%E5%B9%BF%E5%91%8A%E7%89%88.mp4',
      image:
        '/img/video.jpg',
    },
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '我们的优势' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper k8xyqg58htd-editor_css',
    children: [
      {
        name: 'block0',
        className: 'content0-block k8y2vi0ov6b-editor_css',
        md: 4,
        xs: 20,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>高效率</p>
                </span>
              ),
            },
            { name: 'content', children: '支付、结算、核算接入产品效率翻四倍' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block k8y2vf7gjcq-editor_css',
        md: 4,
        xs: 20,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>超快反</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: '在所有需求配置环节事前风险控制和质量控制能力',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block k8y2v78brup-editor_css',
        md: 4,
        xs: 20,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>柔性生产</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: '沉淀产品接入效率和运营小二工作效率数据',
            },
          ],
        },
      },
      {
        name: 'block~k8xypzejoj',
        className: 'content0-block k8y2to45r36-editor_css',
        md: 4,
        xs: 20,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>规模化</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: '沉淀产品接入效率和运营小二工作效率数据',
            },
          ],
        },
      },
      {
        name: 'block~k8y2z6ln4o',
        className: 'content0-block k8y2to45r36-editor_css',
        md: 4,
        xs: 20,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>客户体验</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    按天交货，小批量也能有大批量价格，一站式服务，透明供应链管理。
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
    gutter: 0,
  },
};
export const Pricing00DataSource = {
  wrapper: {
    className: 'home-page-wrapper pricing0-wrapper k8y1kknqqe6-editor_css',
  },
  OverPack: {
    playScale: 0.3,
    className: 'home-page pricing0 k8y1kk15o1p-editor_css',
  },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img k8y1hl6h2r-editor_css',
    name: 'image',
    children:
      '/img/team.jpg',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>我们的团队</p>
            </span>
          </span>
        ),
        className: 'pricing0-title k8y4gkz0lo-editor_css',
      },
      {
        name: 'content~k8y24xqjq3q',
        className: 'k8y2591nwoe-editor_css',
        children: (
          <span>
            <p>
      我们富有创造力的技术团队来自985和211高校和一流的互联网技术公司：清华，浙大，北理工，大连理工，华中科大，百度，华为，微软。这支充满激情，团结互助的团队高效地开发出核心系统。 
            </p>
          </span>
        ),
      },
      {
        name: 'content~k8y1j0ce34c',
        className: 'k8y1j1zf6ml-editor_css',
        children: (
          <span>
            <p>
      身处作为全球服装生产卓越中心（The Global Center of Excellence）的中国，我们产业团队积聚了行业中教育水平最高，技术能力最强的专家，她们的专业能力覆盖了服装生产工艺，生产自动化和技术培训。
            </p>
          </span>
        ),
      },
    ],
  },
};
const icp='京ICP备15022582号-2'
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block1',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <span>
                  <p>
                    <span>地址</span>
                    <br />
                  </p>
                </span>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span class="margin-bottom-16">
                  <p>北京锐界科技有限公司</p>
                  <p>北京市海淀区知春路甲48号盈都大厦C座3单元9C</p>
                  <p>010-5873 3468</p>
                </span>
              ),
            }
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          children: (
            <span>
              <p>联系人</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '',
              name: 'link0',
              children: (
                <span class="margin-bottom-16">
                  <p>金勇（CEO）</p>
                  <p>邮箱：yong.jin@leridge.com</p>
                </span>
              ),
            },
            {
              href: '',
              name: 'link1',
              children: (
                <span>
                  <p>王臻（运营总监）</p>
                  <p>邮箱：wang.zhen@leridge.com</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~k8y1uguwyyo',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          children: (
            <span>
              <p>官网</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '/',
              name: 'link0',
              children: (
                <span>
                  <p>www.leridge.com</p>
                </span>
              ),
              className: 'k8y1vt51u9-editor_css',
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <p>©2017 Leridge All Rights Reserved.</p>
        <p><a href="http://beian.miit.gov.cn/" target="_blank">{icp}</a></p>
      </span>
    ),
  },
};
