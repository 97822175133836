import React from 'react';
export const Banner30DataSource = {
  wrapper: { className: 'banner3 k8ybhr3gpqr-editor_css' },
  textWrapper: {
    className: 'banner3-text-wrapper k8ybk0lbb0s-editor_css',
    children: [
      {
        name: 'slogan',
        className: 'banner3-slogan k8yaz1nv6ut-editor_css',
        children: '我们的服务',
        texty: true,
      },
    ],
  },
};
export const Feature30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block1',
        className: 'content3-block',
        md: 24,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>
                  国际快时尚品牌，在中国有上百家实体店并在各大电商平台开设网店<br />
                </p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            一款时尚度高的秋装新款式，库存风险高，买手预期销售3000件；
                              首批500件销售完成后，品牌判断本款销售后劲不足，修改款式设计，季中补加修改款销售；
                              没有按照初始3000件生产，避免了数千件库存浪费；
                              实现了季中设计修改和补单，充分利用客户反馈信息实现利润优化。
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 24,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>同上国际快时尚品牌</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>
                    一款市场预测会流行的简单秋季款式，买手预期销售5000件；
                      我们提议首批300件，快速出货，之后采用高频追单模式；
                      销售季开始后，该款式没有按照市场预测广泛流行，300件只销售出120件；
                      品牌将剩余100多件从实体店撤出，安排在网店中的折扣部门销售；
                      没有按照3000件预期生产，避免了数千件库存浪费。
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block',
        className: 'content3-block',
        md: 24,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>
                  高端国内设计师品牌，通过买手店买断铺货同时通过电商平台直销粉丝
                </p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>
                  三款时尚度高的冬季大衣，库存风险高，设计师不确定那款会好卖，前几年大衣几百件；
                  销售季开始前三周确定设计，首批每款20件，10件交给买手店展示，10件电商备货；
                  销售季开始后，两款销售缓慢，一款畅销，促销款按照每单40件追单，现已追单5次；
                  对设计师个性化设计感强的时装，销售量无法预测，采用高频快速追单模式避免浪费；
                  如果出现促销款或者爆款，高频快速追单可以在减少退单的同时，最大化爆款的销售。
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~k8ycc0as1ve',
        className: 'content3-block',
        md: 24,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>
                    客户可以通过结合本地产能和低成本国家的产能同时实现利润最大化
                  </p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          低成本国家比如孟加拉和柬埔寨生产成本很低，但是反应慢，备货周期长。我们可以用这些国家的低成本结合我们的高频快反追单能力实现超低成本的超快反应供应链：在销售季开始前数个月，在低成本国家的工厂下单备货，生产量根据大数据计算有很高的售罄概率；在销售季开始后，实时跟踪销售速度，根据实时数据进行高频快速追单；如果出现爆款，迅速扩大即时产能，满足爆款要求；这个模式既可以减少库存浪费，满足爆款需求，还能实现超低价，达到时尚风险管理下的利润最大化。<br />
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Content120DataSource = {
  wrapper: { className: 'home-page-wrapper content12-wrapper' },
  page: { className: 'home-page content12' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>合作品牌</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'img-wrapper k8ycdvghzu-editor_css',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"翰唯时尚",
          img: {
            children:
              '/img/logo1.png',
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"黑桥",
          img: {
            children:
              '/img/logo2.png',
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"卡莉朵拉",
          img: {
            children:
              '/img/logo3.png',
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"力子格",
          img: {
            children:
              '/img/logo4.png',
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"梁诗雨",
          img: {
            children:
              '/img/logo5.png',
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"靓美婷",
          img: {
            children:
              '/img/logo6.png',
          },
        },
      },
      {
        name: 'block~k8ycfyf6r7',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"裂帛",
          img: {
            children:
              '/img/logo7.png',
          },
        },
      },
      {
        name: 'block~k8ycg03tn8',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"玛格丽",
          img: {
            children:
              '/img/logo8.png',
          },
        },
      },
      {
        name: 'block~k8ycg0pvpoj',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"西雅衣家",
          img: {
            children:
              '/img/logo9.png',
          },
        },
      },
      {
        name: 'block~k8ycutxhldt',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"邢永",
          img: {
            children:
              '/img/logo10.png',
          },
        },
      },
      {
        name: 'block~k8ycuufwl8',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"炫裴歌",
          img: {
            children:
              '/img/logo11.png',
          },
        },
      },
      {
        name: 'block~k8ycuuz74ys',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"依文",
          img: {
            children:
              '/img/logo12.png',
          },
        },
      },
      {
        name: 'block~k8ycuw23ay',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"亿尚美",
          img: {
            children:
              '/img/logo13.png',
          },
        },
      },
      {
        name: 'block~k8ycuwj8e',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"友涵诚衣",
          img: {
            children:
              '/img/logo14.png',
          },
        },
      },
      {
        name: 'block~k8ycuwzi9yt',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"原色生活",
          img: {
            children:
              '/img/logo15.png',
          },
        },
      },
      {
        name: 'block~k8ycuxlofis',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"琢赢服装",
          img: {
            children:
              '/img/logo16.png',
          },
        },
      },
      {
        name: 'block~k8ycuy4srm',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"子潇一微",
          img: {
            children:
              '/img/logo17.png',
          },
        },
      },
      {
        name: 'block~k8ycuz49v5',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"北京顺开四府",
          img: {
            children:
              '/img/logo18.png',
          },
        },
      },
      {
        name: 'block~k8ycuzkh1if',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"尚仄",
          img: {
            children:
              '/img/logo19.png',
          },
        },
      },
      {
        name: 'block~k8ycv01qi1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"高田时尚集团",
          img: {
            children:
              '/img/logo20.png',
          },
        },
      },
      {
        name: 'block~k8ycv0hkkfc',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"觉是",
          img: {
            children:
              '/img/logo21.png',
          },
        },
      },
      {
        name: 'block~k8ycv16efdq',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"兰梦萱",
          img: {
            children:
              '/img/logo22.png',
          },
        },
      },
      {
        name: 'block~k8ycv20yh4d',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"有沐",
          img: {
            children:
              '/img/logo23.png',
          },
        },
      },
      {
        name: 'block~k8ycv2gs89',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"凯铂图",
          img: {
            children:
              '/img/logo24.png',
          },
        },
      },
      {
        name: 'block~k8ycxjaihv',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"朗姿",
          img: {
            children:
              '/img/logo25.png',
          },
        },
      },
      {
        name: 'block~k8ycxk4ta38',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"兰玉",
          img: {
            children:
              '/img/logo26.png',
          },
        },
      },
      {
        name: 'block~k8ycxmaxq5b',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"张帅服装",
          img: {
            children:
              '/img/logo27.png',
          },
        },
      },
      {
        name: 'block~k8ycydv8rq9',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          name:"身体主义",
          img: {
            children:
              '/img/logo28.png',
          },
        },
      },
    ],
    gutter: 0,
  },
};
