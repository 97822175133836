import React from 'react';
import { TweenOneGroup } from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row, Col, Divider } from 'antd';
import {
  NewsData
} from './data.source';

const style = { background: '#0092ff', padding: '8px 0' };

class Content12 extends React.PureComponent {
  getChildrenToRender = (data) =>
  // console.log(data)
    data.map((item) => {
      return (
        <Col key={item.name} {...item}>
          <div {...item.children.wrapper}>
            <span {...item.children.img}>
              <img style={{background:'URL('+item.children.img.children+') no-repeat center center'}} alt=""/>
            </span>
          </div>
        </Col>
      );
    });

    totop(type){
      document.documentElement.scrollTop = document.body.scrollTop =0;
    };

  render() {
    const data=NewsData.list
    
    const colChildren=data.map((item)=>
        <Row className="row" gutter={24}>
          <Col className="gutter-row" span={2} xl={12} md={24} sm={24} xs={24}>
            <span className="img-span"></span>
            <div className="gutter-img" style={{background:'URL('+item.img+') no-repeat center center'}} alt=""></div>
          </Col>
          <Col className="gutter-row align-left" span={2} xl={12} md={24} sm={24} xs={24}>
            <div className="title">{item.title}</div>
            <div className="time">{item.time}</div>
              <div>
                  {
                      item.content.map((item)=>
                          <div className="content margin-bottom-24">{item}</div>
                      )
                  }
              </div>

            {/*<div className="content">{item.content}</div>*/}
            <div className="go-detail">
              <a href={"#/news?id="+item.id} onClick={this.totop.bind(this)}>进入详情</a>
              <span className="arrow-right"></span>
            </div>
          </Col>
        </Row>
      
    )
    return (
      <div className="trademark">
        <Row className="row" gutter={24}>
          {colChildren}
        </Row>
      </div>
    );
  }
}

export default Content12;
