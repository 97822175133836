import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import {Row, Col} from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import {getChildrenToRender} from './utils';

class Case extends React.PureComponent {
    getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;

    render() {
        const {...props} = this.props;
        const {dataSource, isMobile} = props;
        delete props.dataSource;
        delete props.isMobile;
        console.log("***************")
        console.log(isMobile)
        let clearFloatNum = 0;
        let data = [
            {
                title: "以纯",
                logo: '/img/yichun-logo.png',
                data: [
                    "车间规模：421m²",
                    "工位/生产人员：29人",
                    "AMR数量：7台"
                ],
                imgList: [
                    "/img/yichun-img1.png",
                    "/img/yichun-img2.png",
                    "/img/yichun-img3.png",
                    "/img/yichun-img4.png"
                ],
                percent: [
                    {name: '生产效率提升：', value: '40%'},
                    {name: '平均工资提升：', value: '18%'}
                ]
            },
            {
                title: "红豆",
                logo: '/img/hongdou-logo.png',
                data: [
                    "车间规模：364m²",
                    "工位/生产人员：23人",
                    "AMR数量：6台"
                ],
                imgList: [
                    "/img/hongdou-img1.png",
                    "/img/hongdou-img2.png",
                    "/img/hongdou-img3.png",
                    "/img/hongdou-img4.png"
                ],
                percent: [
                    {name: '生产效率提升：', value: '24.8%'},
                    {name: '平均工资提升：', value: '21%'}
                ]
            }
        ]

        const colChildren = data.map((item) =>
            <div>
               {/* <Row className="row item-box top" gutter={24}>
                    <Col className="gutter-row content" xl={24} md={24} sm={24} xs={24}>

                        <div className="title"><span className="title-left">{item.title}</span><img src={item.logo}
                                                                                                    alt="img" /></div>
                    </Col>
                </Row>*/}
                <Row className="row item-box" gutter={24}>

                    <Col className="gutter-row content" xl={6} md={6} sm={24} xs={24}>
                        <div className="title"><span className="title-left">{item.title}</span><img src={item.logo}
                                                                                                    alt="img" /></div>
                        <ul>
                            {
                                item.data.map(item1 => {
                                    return <li>
                                        {/*<span className="spot"></span>*/}
                                        {item1}</li>
                                })
                            }
                        </ul>
                        <div>
                            {
                                item.percent.map(item1 => {
                                    return <div className="percent"><h3>{item1.name}</h3> <h4>{item1.value}</h4></div>
                                })
                            }
                        </div>
                    </Col>
                    <Col className="gutter-row img-box" xl={18} md={18} sm={24} xs={24}>
                        {
                            item.imgList.map(item2 => {
                                return <img src={item2} alt="img" />
                            })
                        }
                    </Col>
                </Row></div>
        )
        return (
            <div className="case-out">
                <div className="case">
                    <div class="module-title">
                        <span class="underline">案</span>例
                        <span className="title-tip">
              CASE
            </span>
                    </div>
                    {colChildren}

                </div>
            </div>
        );
    }
}

export default Case;
